body {
  margin: 0;
  padding: 0;
  background: #F2F2F2;
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
}
.image-container {
  position: absolute;
  z-index: 9999;
  top: -65px;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  max-width: 200px;
  text-align: center;
}

.about-us-images{
  width: 290px;
  position: relative;
}
.about-us-images .image-left{
  width: 100%;
}
.about-us-images .image-right{
  width: 100%;
}
.carousel-item {
  height: 100vh; /* Set your desired height */
  overflow: hidden;
  position: relative;
}
@media(max-width:500px){
  .caption-heading{
    font-size: 20px !important;
  }
}

.carousel-item img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.carousel-caption {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.5); /* Optional: adds a semi-transparent background */
  color: #fff; /* Optional: adjusts text color for better readability */
  text-align: center;
}
.carousel-caption-custom {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;  /*Centers horizontally */
  align-items: center; /* Centers vertically */
  background: rgba(0, 0, 0, 0.5); /* Optional: adds a semi-transparent background */
  color: #fff; /* Optional: adjusts text color for better readability */
  text-align: center;
  padding: 1rem;
}

.carousel-indicators{
  display: none;
}

.caption-heading {
  margin-bottom: 0;
  font-size: 60px; /* Adjust the font size */
  font-weight: 700; /* Bold text */
  font-family: "Montserrat", sans-serif; /* Ensure the font-family is applied */
  color: "red"; /* Replace with your desired text color */
  /* background: rgba(0, 0, 0, 0.5); */
}


.my-cat-card {
  background-position: center;
  background-size: cover;
  display: flex;
  height: 330px;
  flex-direction: column;
  transition: transform 0.3s ease-in-out;
  text-align: center;
  margin-top: 80px;
}

.selected-category{
  transform: scale(1.2);
}

.selected-category .cbody{
  background: linear-gradient(0deg, #cdc6c6, #F5F5F5);
}

.my-cat-card:hover {
  transform: scale(1.2); /* Slightly enlarge the card on hover */
}

.my-cat-card:hover .cbody{
  background: linear-gradient(0deg, #cdc6c6, #F5F5F5);
}

.cbody{
  box-shadow: 0px 0px 50px 0px #0000000D;
  background: linear-gradient(0deg, #F5F5F5, #F5F5F5);
  border-radius: 10px;
  border: 1px solid white;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 210px;
  margin-top: auto;
  text-align: center;
  padding: 20px;
  position: relative;
}

.cbody h5 {
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}

.cbody .text-bigliter {
  text-align: center;
  width: 100%;
}

.ebody{
  /* background-image: url(assetr/ebody.png); */
  /* background: linear-gradient(0deg, #F5F5F5, #F5F5F5); */
    border-radius: 10px;
    border: 1px solid white;
    justify-content: center;
    display: flex;
    height: 300px;
    margin: auto;
   
}
/* Position button in the center and make it appear only on hover */

.btn-primary {
  position: absolute;
  bottom: 10px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.my-card:hover .btn-primary {
  opacity: 1;
}


/* fonts */
.bg-colornav{
  background-color: 
  #F5F5F5;
  box-shadow: 0px 0px 24px 0px #00000014;

}
li {
  list-style: none;
}
.btn-more{
  background: #AE040D;
color: #FFFFFF;
border: 1px solid #AE040D;
}
.bg-home{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mycard{
  background-color: #F5F5F5;
  box-shadow: 0px 0px 30px 0px #0000000D;

}
.language-select {
  margin-top: 0; /* Ensure there's no margin at the top */
}

/* Additional styling if needed */
.language-select .react-select__control {
  min-height: 34px; /* Adjust height as needed */
  border-radius: 4px; /* Adjust border radius if necessary */
}

.text-colorwhite{
  color:#FFFFFF;
}
.text-colored{
  color: #AE040D;
}
.text-colorblack{
  color: #252525;

}
.text-colorgray{
  color: #767C8C !important;

}

.setcontainer {
  bottom: -130px;
  display: none;
}
.my-card{
  background: linear-gradient(0deg, #F5F5F5, #F5F5F5);
  border-radius: 10px;


}
.form-select:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}

.window-Card .slick-next:before {
  color: white !important;
  background-color: 
  #AE040D;
  padding: 14px 14px 13px 16px !important;
  border-radius: 50px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) !important;
}
.window-Card .slick-prev:before {
  color: white !important;
  background-color: #AE040D;
  padding: 14px 14px 13px 16px !important;
  border-radius: 50px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) !important;
}
.window-Card .slick-prev {
  z-index: 99;
  left: -32px !important;
}
.window-Card .slick-next {
  right: -10px;
  z-index: 99;
}
.window-Card .slick-next:before {
  content: "\f061" !important;
  font-family: "fontawesome" !important;
}
.window-Card .slick-prev:before {
  font-family: "fontawesome" !important;
  content: "\f060" !important;
}


.Testimonial .slick-next:before {
  color: 
  #AE040D !important;
 
}
.Testimonial .slick-prev:before {
  color: 
  #AE040D !important;

}
.Testimonial .slick-prev {
  left:41%;
  z-index: 99;
  bottom: -36px !important;
}
.Testimonial .slick-next {
  left: 57%;
  z-index: 99;
  bottom: -36px !important;
}
.Testimonial .slick-next:before {
  content: "\f178" !important;
  font-family: "fontawesome" !important;
}
.Testimonial .slick-prev:before {
  font-family: "fontawesome" !important;
  content: "\f177" !important;
}
.Testimonial .slick-prev {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top:  107%;
}
.Testimonial .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top:  107%;
}
.bg-colorblack{
  background: #5656563D;
}
.bg-contactus{
  display: flex;
  align-items: center;
  justify-content: center;
}
#contact{
  background: #c2c2c2;
  padding: 20px;
}
/* font Families */
.font-inter {
  font-family: "Montserrat", sans-serif;
  white-space: pre-line;
  /* font-family: 'Inter', sans-serif; */
}

.product-subtitle
{
  padding-top: 20px;
}

.product-description
{
  text-align: justify;
}

.copyright {
  font-size: 10px;
  color: #a8a8a8;
}
.dev {
  font-size: 10px;
  color: #a8a8a8;
}
.dev:hover {
  font-size: 12px;
  color: #cec2c2 !important;
}

/* Css Font-sizes */
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-20 {
  font-size: 20px;
}
.font-45 {
  font-size: 45px;
}


/* Css Font Weight  */
.fw-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}

.fw-600 {
  font-weight: 600;
}

a {
  color: #495057 !important;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
}
a.active{
  color: #AE040D !important;
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
}
a:hover{
  color: #AE040D !important;
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
}

/* Navbar End */


/* Profile Css start */
.profileinfo{
  color: #FFFFFF;
  box-shadow: 0px 0px 40px 0px #5E5C9A1F;
  background: #2A3A4A;
}
.border-Files{
  border:1px solid #a8a8a8;
  color: #FFFFFF;
  border-radius: 8px;
}
.Input-filed::placeholder{
  color: #FFFFFF;
}
.borderleft{
  border-right: 2px solid #3333331A;
}
.borderbottom{
  border-bottom: 2px solid #3333331A;
  
}
.no-bg {
  background-color: transparent !important; /* Remove background color for the table */
}

.no-bg td {
  background-color: transparent !important; /* Remove background color for table cells */
}

.border-bottom {
  border-bottom: 1px solid #000; /* Bottom border styling */
}
.border-right{
  border-right: 2px solid #dee2e6;
}
.bg-colorfotter{
  background-color: 
  #1A1A1A;
}
.btn-signup{
  background-color: #BF0A30 !important;
  border: 1px solid #BF0A30 !important;
  color: white !important;
}
.bottomline{
  border-bottom: 2px solid #AE040D;
}
.btn-white{
  border: 1px solid #DCDCDC
}

.language-selector{
  cursor: pointer;
  z-index: 9999;
  width: auto;
  max-width: 300px; /* Adjust this value as needed */
}
@media (max-width: 768px) {
  .bg-home{
    height: auto;
  }
  .bg-contactus{
    height: auto;
  }
  .slick-next{
    display: none !important;
  }
  .slick-prev{
    display: none !important;
  }
  .setcontainer {
    bottom: -130px;
    display: none;
}
  .navbar-toggler {
    position: absolute;
    top: 15px;
    right: 70px; /* Decrease this value to move the hamburger menu more to the left */
    z-index: 1000;
  }
  .navbar-nav .language-selector {
    width: 100%;
    max-width: 200px; /* Increased width for mobile */
    margin: 10px auto;
  }

  .navbar-nav .language-selector .css-26l3qy-menu {
    width: 100%;
    max-width: 200px; /* Match the max-width above */
  }
}

@media (max-width: 768px) {
  .window-Card .slick-prev,
  .window-Card .slick-next {
    width: 27px;
    height: 27px;
  }

  .window-Card .material-icons {
    font-size: 18px;
  }

  .window-Card .slick-prev {
    left: -15px;
  }

  .window-Card .slick-next {
    right: -15px;
  }

  .my-cat-card {
    height: 280px;
    margin-top: 40px;
  }

  .cbody {
    height: 180px;
  }

  .image-container {
    top: 15px;
    width: 50%;
  }
}

@media (max-width: 480px) {
  .my-cat-card {
    margin-top: 50px;
    height: 300px;
  }

  .image-container {
    top: -35px;
    width: 50%;
    max-width: 150px;
  }

  .cbody {
    height: 180px;
    padding: 15px;
  }

  .cbody h5 {
    font-size: 14px;
    margin-bottom: 8px;
    margin-top: auto;
  }

  /* Ensure arrows are visible on iPhone */
  .window-Card .slick-prev {
    left: 0;
  }

  .window-Card .slick-next {
    right: 0;
  }
}

.slick-slider .slick-prev,
.slick-slider .slick-next {
  z-index: 1;
  width: 30px;
  height: 30px;
  opacity: 1 !important; /* Force visibility */
}

.slick-slider .slick-prev {
  left: 10px !important;
}

.slick-slider .slick-next {
  right: 10px !important;
}

.slick-slider .slick-prev:before,
.slick-slider .slick-next:before {
  font-size: 30px;
  color: #000;
  opacity: 1 !important; /* Force visibility */
}

.window-Card .slick-prev,
.window-Card .slick-next {
  background-color: rgba(174, 4, 13, 0.8);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 99;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border: none;
  outline: none;
  position: absolute;
  top: 70%;
  transform: translateY(-50%);
}

.window-Card .slick-prev {
  left: -20px;
}

.window-Card .slick-next {
  right: -20px;
}

.window-Card .slick-prev:hover,
.window-Card .slick-next:hover,
.window-Card .slick-prev:focus,
.window-Card .slick-next:focus,
.window-Card .slick-prev:active,
.window-Card .slick-next:active {
  background-color: rgba(174, 4, 13, 1);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
  transform: scale(1.05);
  color: white;
  outline: none;
}

.window-Card .slick-prev:before,
.window-Card .slick-next:before {
  display: none;
}

.window-Card .material-icons {
  font-size: 27px;
  line-height: 1;
  display: block !important;
  user-select: none;
  pointer-events: none;
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .window-Card .slick-prev,
  .window-Card .slick-next {
    width: 32px;
    height: 32px;
    top: 65%;
  }

  .window-Card .material-icons {
    font-size: 22px;
  }

  .window-Card .slick-prev {
    left: -16px;
  }

  .window-Card .slick-next {
    right: -16px;
  }

  .image-container {
    top: -25px;
    width: 65%;
    max-width: 250px;
  }
}

@media (max-width: 768px) {
  .my-cat-card {
    margin-top: 60px;
  }

  .image-container {
    top: -25px;
    width: 65%;
    max-width: 250px;
  }

  .cbody h5 {
    margin-bottom: 10px;
  }
}

/* Cookie Consent Modal Styles */
.cookie-modal-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
}

.cookie-modal-content {
  background-color: #f0f0f0;
  padding: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  max-width: 600px;
  width: 100%;
  color: #333;
  font-size: 14px;
}

.cookie-modal-title {
  margin-top: 0;
  color: #333;
  font-size: 18px;
}

.cookie-modal-message {
  color: #666;
  margin-bottom: 15px;
}

.cookie-button-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.cookie-button {
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.3s;
  font-size: 14px;
}

.cookie-button:hover {
  transform: scale(1.05);
}

.cookie-button-primary {
  background-color: #4CAF50;
  color: white;
}

.cookie-button-primary:hover {
  background-color: #45a049;
}

.cookie-button-secondary {
  background-color: #e0e0e0;
  color: #333;
}

.cookie-button-secondary:hover {
  background-color: #d0d0d0;
}

.newsletter-form {
  width: 100%;
}

.newsletter-form form {
  width: 100%;
}

.newsletter-form input {
  flex: 1;
}

.newsletter-form button {
  white-space: nowrap;
}

@media (max-width: 575px) {
  .newsletter-form button {
    margin-top: 10px;
    width: 100%;
  }
}
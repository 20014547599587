.owl-theme .owl-nav {
  display: none;
}

.banner {
  background-image: url('../public/images/landing-page/slide-1.jpg');
  background-size: cover;
  /* opacity: 0.8; */
  background-position: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.text-line {
  color: white;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.1);
  /* border: 2px solid lightgray; */
  border-radius: 15px;
  padding: 5px 5px;
  position: absolute;
  /* left: 10%;  */
  bottom: 50%;
  /* Position the text in the middle vertically */
  font-size: 2.5em;
  /* Adjust this value as needed */
  font-weight: bold;
  /* Optional: to make the text bold */
  opacity: 0;
}

.text-line.show {
  animation: flyIn 3s forwards, disappear 3s 3s forwards;
}

@keyframes flyIn {
  0% {
    bottom: -100px;
    opacity: 0;
  }

  100% {
    bottom: 50%;
    opacity: 1;
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}